<template>
  <div class="patient-panel ps-8 pe-8" v-if="pageLoaded">
    <div class="patient-newRequest">
      <v-row>
        <v-col>
          <v-card style="border-radius: 17px">
            <v-row>
              <v-col class="mb-0 pb-0">
                <v-btn
                  class="purple-btn mt-6 pa-8"
                  @click="step = 1"
                  v-if="step == 2"
                >
                  <v-icon class="me-4">mdi-arrow-right</v-icon>
                  <h5 class="mt-1 font-weight-bold">مرحله قبل</h5>
                </v-btn></v-col
              >
              <v-col class="mb-0 pb-0" cols="8">
                <div class="image-holder">
                  <img
                    src="../../assets/images/big.png"
                    width="300"
                    height="100"
                    class="image"
                  />
                </div>
                <div class="center-col text-right mt-2">
                  <h6 class="mb-5 font-weight-bold">
                    <v-icon right class="primary--text"> schedule </v-icon>
                    کاربر گرامی،
                    <span>{{ toHHMMSS(chooseUserCounter) }} </span>
                    زمان باقی مانده است تا خدمت خود را ثبت کنید.
                  </h6>
                </div></v-col
              >
              <v-col class="mb-0 pb-0">
                <v-btn
                  class="float-end purple-btn mt-6 pa-8"
                  @click="step = 2"
                  :disabled="
                    !inputs.firstName || !inputs.lastName || !inputs.mobile
                  "
                  v-if="step == 1"
                >
                  <h5 class="mt-1 font-weight-bold">مرحله بعد</h5>
                  <v-icon class="ms-4">mdi-arrow-left</v-icon>
                </v-btn></v-col
              >
            </v-row>

            <div v-if="step == 1">
              <div
                class="pa-5"
                style="
                  border-radius: 30px;
                  background-color: rgba(187, 187, 187, 0.196);
                "
              >
                <div class="px-4">
                  <v-row class="d-flex">
                    <v-col
                      v-for="key in Object.keys(inputs)"
                      :key="key"
                      cols="6"
                    >
                      <Input
                        :inputs="inputs"
                        :inputName="key"
                        @onInputFocus="onInputFocus"
                        @onInputChange="onInputChange"
                      />
                    </v-col>
                  </v-row>
                </div>
              </div>
              <hr class="mb-3 mt-3" />
              <SimpleKeyboard
                @onChange="onChange"
                @onKeyPress="onKeyPress"
                :input="inputs[inputName]"
                :inputName="inputName"
              />
            </div>
            <div v-else-if="step == 2">
              <h5 class="grey--text text--darken-3 my-6 font-weight-bold ms-4">
                <v-icon class="primary--text">mdi-flare</v-icon>
                خدمت مورد نظر خود را انتخاب کنید:
              </h5>
              <div>
                <v-row class="d-flex">
                  <v-col
                    cols="3"
                    class="text-center"
                    v-for="service in serviceTypes"
                    :key="service.value"
                  >
                    <div
                      :class="chooseServiceClass(service.value, service.color)"
                      @click="selectType(service.value)"
                    >
                      <span
                        class="font-weight-bold"
                        style="font-size: 22px !important"
                        >{{ service.text }}</span
                      >
                    </div>
                  </v-col>
                </v-row>
                <hr class="my-6" />
                <div v-if="gettingList && chosenType" class="text-center mt-16">
                  <v-progress-circular
                    indeterminate
                    size="50"
                    color="primary"
                  ></v-progress-circular>
                  <h5 class="mt-4 font-weight-bold">
                    در حال بارگذاری اطلاعات...
                  </h5>
                </div>
                <div
                  v-else-if="!gettingList && chosenType"
                  class="pa-6"
                  ref="list"
                >
                  <v-row v-if="chosenType != 'visit'">
                    <v-col>
                      <h5 class="mb-3 grey--text text--darken-3 ms-4">
                        <strong>لیست خدمات</strong>
                      </h5>
                      <v-row>
                        <v-col class="mx-auto pb-0" cols="12">
                          <v-row class="mt-5">
                            <v-col class="text-center"
                              ><span
                                class="font-weight-bold text--darken-2"
                                style="font-size: 20px"
                              >
                                تصویر
                              </span></v-col
                            >
                            <v-col class="text-center"
                              ><span
                                class="font-weight-bold text--darken-2"
                                style="font-size: 20px"
                              >
                                نام خدمت
                              </span></v-col
                            >
                            <v-col class="text-center"
                              ><span
                                class="font-weight-bold text--darken-2"
                                style="font-size: 20px"
                              >
                                قیمت
                              </span></v-col
                            >
                            <v-col class="text-center"
                              ><span
                                class="font-weight-bold text--darken-2"
                                style="font-size: 20px"
                              >
                                عملیات
                              </span></v-col
                            >
                          </v-row>
                          <hr />
                        </v-col>
                      </v-row>
                      <div class="mt-5">
                        <v-row>
                          <v-col
                            cols="12"
                            v-for="(service, i) in computedServices"
                            :key="i"
                            class="request-item py-8"
                          >
                            <v-row>
                              <v-col class="d-flex justify-content-center">
                                <v-img
                                  :src="
                                    service.picture
                                      ? service.picture
                                      : '/image-placeholder.png'
                                  "
                                  alt=""
                                  max-width="200"
                                  height="auto"
                                  style="border-radius: 30px"
                                ></v-img
                              ></v-col>
                              <v-col
                                class="d-flex justify-content-center align-items-center"
                                ><h6
                                  class="grey--text text--darken-3 font-weight-bold"
                                >
                                  {{ service.name }}
                                </h6></v-col
                              >
                              <v-col
                                class="d-flex justify-content-center align-items-center"
                                ><h6
                                  class="grey--text text--darken-3 font-weight-bold"
                                >
                                  {{
                                    Number(service.cost).toLocaleString() +
                                    " ریال "
                                  }}
                                </h6></v-col
                              >
                              <v-col
                                class="d-flex justify-content-center align-items-center"
                              >
                                <v-btn
                                  class="primary-btn pa-10"
                                  @click="addtoNewService(service)"
                                >
                                  <v-icon class="me-1">mdi-plus-thick</v-icon>
                                  <h4 class="mt-1">افزودن</h4>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col class="ma-auto" cols="6">
                      <div class="div-doctor">
                        <v-row>
                          <v-col cols="5">
                            <v-img
                              height="200"
                              width="200"
                              class="img-doctor"
                              :src="
                                services.visit.picture
                                  ? services.visit.picture
                                  : 'https://www.sibberhuuske.nl/wp-content/uploads/2016/10/default-avatar-300x300.png'
                              "
                            ></v-img
                          ></v-col>
                          <v-col>
                            <div class="pa-5">
                              <h5
                                class="font-weight-bold grey--text text--darken-2 mt-5"
                              >
                                پزشک معالج شما:
                              </h5>
                              <h5 class="font-weight-bold my-5">
                                <v-icon>mdi-doctor</v-icon>
                                دکتر {{ services.visit.doctorName }}
                              </h5>
                              <h6>
                                <v-icon>mdi-cash-multiple</v-icon>
                                هزینه ویزیت:
                                <span>
                                  {{
                                    Number(services.visit.cost).toLocaleString()
                                  }}
                                  ریال</span
                                >
                              </h6>
                            </div></v-col
                          >
                          <v-col
                            class="pa-0 d-flex justify-content-end"
                            cols="12"
                          >
                            <span
                              ><v-icon
                                class="green--text text--darken-2"
                                style="font-size: 40px"
                                >mdi-check-decagram</v-icon
                              ></span
                            >
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                  <hr class="mt-10" />
                </div>
                <div v-if="newItems.length">
                  <v-row>
                    <v-col class="pt-0">
                      <h5 class="mb-3 grey--text text--darken-3">
                        <strong>خدمات درخواستی</strong>
                      </h5>

                      <b-table
                        :fields="serviceFields"
                        :items="newItems"
                        :current-page="newCurrentPage"
                        :per-page="newPerPage"
                        responsive
                      >
                        <template v-slot:head()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.label }}
                          </div>
                        </template>
                        <template v-slot:cell()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.value }}
                          </div>
                        </template>
                        <template v-slot:cell(index)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{
                              data.index + newPerPage * (newCurrentPage - 1) + 1
                            }}
                          </div>
                        </template>
                        <template v-slot:cell(clinicShare)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(description)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{
                              data.item.description.substr(0, 100) +
                              (data.item.description.length > 100 ? "..." : "")
                            }}
                          </div>
                        </template>
                        <template v-slot:cell(patientCost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(cost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(operation)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            <v-btn
                              outlined
                              class="red-btn py-5 ps-8 pe-8 font-weight-bold"
                              @click="delService(data.index)"
                              ><v-icon class="me-1"
                                >mdi-delete-empty-outline</v-icon
                              >
                              <h5 class="mt-1">حذف</h5>
                            </v-btn>
                          </div>
                        </template>
                        <div
                          slot="table-busy"
                          class="text-center primary--text my-2"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            class="align-middle"
                          ></v-progress-circular>
                        </div>
                      </b-table>
                      <v-pagination
                        v-model="newCurrentPage"
                        :length="Math.ceil(newTotalRows / newPerPage)"
                        :total-visible="5"
                        prev-icon="arrow_back"
                        next-icon="arrow_forward"
                        style="float: center"
                      ></v-pagination>
                      <hr class="my-10" />
                    </v-col>
                  </v-row>
                </div>
                <div
                  class="d-flex align-items-center mb-8"
                  v-if="newItems.length || chosenType == 'visit'"
                >
                  <h5>
                    <strong
                      >قیمت کل:
                      {{
                        newItems.length
                          ? newItems
                              .map((x) => x.patientCost)
                              .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                              .toLocaleString()
                          : Number(services.visit.cost).toLocaleString()
                      }} </strong
                    >ریال
                  </h5>
                  <v-btn
                    class="green-btn pa-10 ms-auto font-weight-bold"
                    @click="submit()"
                    :loading="addRequestBtnLoading"
                  >
                    <v-icon class="me-1">mdi-check-underline</v-icon>
                    <h5 class="mt-1">ثبت درخواست</h5>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!-- receipt -->
      <v-card v-show="false" id="Receipt" v-if="isMounted">
        <div class="container mb-0 pa-3" style="border: 2px solid black">
          <div
            style="
              text-align: center;
              margin: auto;
              font-size: 15px;
              border-bottom: 4px solid black;
            "
          >
            <img src="/receiptLogo.png" width="100" height="100" alt="" />
            <br />
            درمانگاه شبانه روزی پارسیان البرز
          </div>

          <p style="direction: rtl; padding: 10px">
            شماره نوبت:
            <span>{{ printInfo.visitNo }}</span>
            <br />
            <span>
              {{
                chosenType == "dentist" ? "نام دندانپزشک:" : " نام پزشک: "
              }}</span
            >
            {{ printInfo.docName }}
            <br />
            <span>
              {{
                chosenType == "dentist" ? "نام دستیار:" : " نام پرستار: "
              }}</span
            >
            {{ printInfo.nurseName ? printInfo.nurseName : "-" }}
            <br />
            {{
              "نام پذیرش: " +
              (printInfo.receptionName ? printInfo.receptionName : "-")
            }}
            <br />
            {{ "نام بیمار: " + printInfo.patientName }}
            <br />
            {{ "کد اشتراک بیمار: " + printInfo.patientCode }}
            <br />
            {{
              "زمان مراجعه: " +
              " تاریخ " +
              printInfo.date.split(" ")[0] +
              " " +
              " ساعت " +
              " " +
              printInfo.date.split(" ")[1]
            }}
          </p>

          <b-table
            bordered
            :fields="printFields"
            :items="printInfo.services"
            small="small"
            responsive
            style="padding: 10px"
          >
            <template v-slot:head()="data">
              <div style="text-align: center; vertical-align: middle">
                {{ data.label }}
              </div>
            </template>
            <template v-slot:cell(name)="data">
              <div style="text-align: center; vertical-align: middle">
                {{ data.value }}
              </div>
            </template>
            <template v-slot:cell(number)="data">
              <div style="text-align: center; vertical-align: middle">
                {{ data.value }}
              </div>
            </template>
            <template v-slot:cell(cost)="data">
              <div style="text-align: center; vertical-align: middle">
                {{ Number(data.item.cost).toLocaleString() }}
              </div>
            </template>
          </b-table>
          <h4 style="padding: 10px">
            {{ "مبلغ کل:" + Number(printInfo.cost).toLocaleString() + "ریال" }}
          </h4>
          <div>
            <div style="text-align: right; font-weight: 900; padding: 10px">
              لطفا برای پرداخت هزینه ویزیت، به پذیرش مراجعه نمایید.
            </div>
          </div>
          <hr />
        </div>
        <br />
        <!-- <barcode value="http://my.parsianalborz.com" displayValue="false" width="2" height="60">
        </barcode>   -->
        <h4 style="text-align: center">http://my.parsianalborz.com</h4>
      </v-card>
    </div>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import SimpleKeyboard from "./SimpleKeyboard";
import Input from "./Input";
export default {
  components: {
    SimpleKeyboard,
    Input,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      nationalCode: "",
      mobile: "",
      pageLoaded: false,
      moment: moment,
      patientInfo: {},
      serviceType: "",
      chosenType: "",
      serviceTypes: [
        {
          text: "ویزیت پزشک عمومی",
          value: "visit",
          color: "purple",
        },
        {
          text: "خدمات پزشک",
          value: "injection",
          color: "red",
        },
        {
          text: "خدمات دندانپزشکی",
          value: "dentist",
          color: "blue",
        },
        {
          text: "خدمات پرستاری",
          value: "clinical",
          color: "green",
        },
      ],
      serviceFields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام خدمت" },
        { key: "cost", label: "قیمت (ریال)" },
        { key: "operation", label: "عملیات" },
      ],
      printFields: [
        { key: "name", label: "خدمت" },
        { key: "number", label: "تعداد" },
        { key: "cost", label: "قیمت (ریال)" },
      ],
      services: [],
      services: {},
      serviceCurrentPage: 1,
      servicePerPage: 10,
      newItems: [],
      newCurrentPage: 1,
      newPerPage: 10,
      serviceBusy: false,
      serviceTotalRows: "",
      newTotalRows: "",
      gettingList: false,
      chooseUserCounter: 300,
      addRequestBtnLoading: false,
      date: moment(new Date()).format("jYYYY/jMM/jDD HH:MM"),
      patientId: window.localStorage.patientId,
      patientInfo: {},
      printInfo: {},
      isMounted: false,
      inputs: {
        firstName: "",
        lastName: "",
        mobile: "",
        nationalCode: "",
      },
      inputName: "input1",
      pageLoaded: false,
      step: 1,
      interval: "",
    };
  },
  methods: {
    selectType(type) {
      this.newItems = [];
      this.chosenType = type;
      this.playVoice(type);
      setTimeout(() => {
        this.scrollToList();
      }, 200);
    },
    playVoice(type) {
      if (type == "visit") {
        var audio = new Audio("/visit.m4a");
        audio.play();
      } else {
        var audio = new Audio("/services.m4a");
        audio.play();
      }
    },
    getRequirements(type) {
      this.chosenType = type;
      if (type != "public") {
        this.gettingList = true;
        this.serviceBusy = true;
        this.$http
          .get(this.baseUrl + "/clinic/patientPanel/dashboard/requirements", {})
          .then((res) => {
            this.serviceBusy = false;
            this.gettingList = false;
            if (res.status == 200) {
              this.services = res.data;
              setTimeout(() => {
                this.pageLoaded = true;
              }, 200);
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.serviceBusy = false;
            this.gettingList = false;

            this.toast("خطا: " + err.response.data, "error");
          });
      } else {
        this.serviceBusy = false;
      }
    },
    submit() {
      if (
        !this.inputs.firstName ||
        !this.inputs.lastName ||
        !this.inputs.mobile
      ) {
        let text =
          "کاربر گرامی، ابتدا موارد بخش اطلاعات کاربر را وارد کنید و سپس مجددا امتحان کنید.";
        this.toast(text, "error", 5000);
      } else {
        this.addRequestBtnLoading = true;
        let endpoint =
          this.chosenType == "dentist"
            ? "/clinic/patientPanel/visit/newDentistry"
            : "/clinic/patientPanel/visit/new";
        this.$http
          .post(
            this.baseUrl + endpoint,
            {
              firstName: this.inputs.firstName,
              lastName: this.inputs.lastName,
              mobile: this.inputs.mobile,
              nationalCode: this.inputs.nationalCode,
              visitType:
                this.chosenType != "dentist" ? "خدمات عمومی" : undefined,
              date: this.date,
              totalCost: this.newItems.length
                ? String(
                    this.newItems
                      .map((x) => x.patientCost)
                      .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                  )
                : this.services.visit.cost,
              paymentStatus: "partial",
              paidCost: "0",
              services: this.newItems.length
                ? JSON.stringify(this.newItems)
                : JSON.stringify([this.services.visit]),
            },
            {}
          )
          .then((res) => {
            this.addRequestBtnLoading = false;
            if (res.status == 201) {
              this.toast("درخواست خدمت به ثبت رسید", "success");
              this.printInfo = res.data;
              this.isMounted = true;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .then(() => {
            this.popup();
            this.isMounted = false;
          })
          .then(() => {
            setTimeout(() => {
              this.logout();
            }, 800);
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.addRequestBtnLoading = false;
          });
      }
    },
    chooseServiceClass(value, color) {
      if (this.chosenType == value) {
        return `${color}-active`;
      } else {
        if (this.services[value].length == 0 || !this.services.visit) {
          return "disabled-service";
        } else {
          return `${color}-service`;
        }
      }
    },
    popup() {
      var myWindow = window.open("#", "Receipt", "height=auto,width=800");
      myWindow.document.write(
        "<html><head><link rel='stylesheet' href='/receipt.css'><title>Receipt</title>"
      );
      myWindow.document.write("</head><body>");
      myWindow.document.write(document.getElementById("Receipt").innerHTML);
      myWindow.document.write("</body></html>");
      myWindow.document.close();

      myWindow.onload = function () {
        myWindow.focus();
        setTimeout(() => {
          myWindow.print();
          myWindow.close();
        }, 500);
      };
    },
    addtoNewService(item) {
      this.serviceTotalRows = this.services.length;
      this.newItems.push(item);
      this.newTotalRows = this.newItems.length;
    },
    delService(order) {
      this.newItems.splice(order, 1);
    },
    chooseUserTimer() {
      this.interval = setInterval(() => {
        this.chooseUserCounter = this.chooseUserCounter - 1;
        if (this.chooseUserCounter == 0) {
          this.logout();
          clearInterval(this.interval);
        }
      }, 1000);
    },
    playSound(sound) {
      if (sound) {
        var audio = new Audio(sound);
        audio.setAttribute("autoplay", true);
      }
    },
    toHHMMSS(value) {
      // NOTE (m-toHHMMSS) determine the order of hours, minutes and seconds
      var sec_num = parseInt(value, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return minutes + ":" + seconds;
    },
    logout() {
      this.$router.push("/patientPanel/main");
    },
    onChange(input) {
      this.inputs[this.inputName] = input;
    },
    onKeyPress(button) {
      //console.log("button", button);
    },
    onInputChange(input) {
      //console.log("Input changed directly:", input.target.id);
      this.inputs[input.target.id] = input.target.value;
    },
    onInputFocus(input) {
      //console.log("Focused input:", input.target.id);
      this.inputName = input.target.id;
    },
    scrollToList() {
      this.$refs["list"].scrollIntoView({ behavior: "smooth" });
    },
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.interval);
    next();
  },
  computed: {
    computedServices() {
      let selectedService;
      switch (this.chosenType) {
        case "injection":
          selectedService = this.services.injection;
          break;
        case "clinical":
          selectedService = this.services.clinical;
          break;
        case "dentist":
          selectedService = this.services.dentist;
      }
      this.serviceTotalRows = selectedService ? selectedService.length : 0;
      return selectedService;
    },
  },
  mounted() {
    this.chooseUserTimer();
    this.getRequirements();
    setTimeout(() => {
      this.playSound("/hello.m4a");
    }, 500);
  },
};
</script>

<style lang="scss">
.image-holder {
  width: 100%;
  display: flex;
  justify-content: center;
}
.image {
  margin-top: 20px;
}
.btn-table:hover {
  transform: translateY(0px) !important;
}
</style>
